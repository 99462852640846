
@import "less/bootstrap.less";
@import "less/variables.less";

// Full width containers
// @container-large-desktop: 100%;

// Define all S2R customizations here

@gray-base:              #000;
@brand-gray:      #f8f8f8;
@brand-bar-color: #f8f8f8;
@brand-danger: #493625;

@brand-primary:   #8cc63f;
@brand-secondary: lighten(@gray-base, 93%);
@brand-tertiary:  #493117;

@brand-info:            #5bc0de;
@brand-warning:         #f0ad4e;
@brand-danger:          #493117;

@colors: {
  primary: @brand-primary;
  secondary: @brand-secondary;
  tertiary: @brand-tertiary;
  info: @brand-info;
  warning: @brand-warning;
  danger: @brand-tertiary;
  error: #D43F39;

  green1: #8cc63f;
  green2: #7cae3b;
  green3: #66952e;
  green4: #3a6c34;
  green5: #eeffd7;
};

each(@colors, .(@value, @key, @index) {

  .bg-@{key} {
    background-color: @value;
  }

  .text-@{key} {
    color: @value;
  }

  .border-@{key} {
    border-color: @value;
  }

});


.s2r-background-primary {
  background-color: @brand-primary;
  color: #fff;
}

.s2r-background-secondary {
  background-color: @brand-secondary;
}

.s2r-background-color {
  background-color: @brand-bar-color;
}

.bg-gray {
  background-color: @brand-gray;
}

table thead {
  background: @brand-primary;
  color: #fff;
}

table {
  border: 1px solid #8cc63f;
}

a.sr2-deco-none {
  color: @text-color;
  text-decoration: none;
}

@btn-secondary-color:              #fff;
@btn-secondary-bg:                 @brand-secondary;
@btn-secondary-border:             darken(@btn-secondary-bg, 5%);

.btn-secondary {
  .button-variant(@btn-default-color; @btn-secondary-bg; @btn-secondary-border);
}

@s2r-btn-nofill-primary-color:               @brand-primary;
@s2r-btn-nofill-primary-bg:                  #fff;
@s2r-btn-nofill-primary-border:              darken(@brand-primary, 5%);

.s2r-btn-nofill-primary {
  .button-variant(@s2r-btn-nofill-primary-color; @s2r-btn-nofill-primary-bg; @s2r-btn-nofill-primary-border);
}

@s2r-btn-fill-primary-color:               #fff;
@s2r-btn-fill-primary-bg:                  @brand-primary;
@s2r-btn-fill-primary-border:              darken(@brand-primary, 5%);

.s2r-btn-fill-primary {
  .button-variant(@s2r-btn-fill-primary-color; @s2r-btn-fill-primary-bg; @s2r-btn-fill-primary-border);
}

@s2r-btn-nofill-danger-color:               @brand-danger;
@s2r-btn-nofill-danger-bg:                  #fff;
@s2r-btn-nofill-danger-border:              darken(@brand-danger, 5%);

.s2r-btn-nofill-danger {
  .button-variant(@s2r-btn-nofill-danger-color; @s2r-btn-nofill-danger-bg; @s2r-btn-nofill-danger-border);
}

//------------------------------------------------
// Settings
//------------------------------------------------
@spacer: 1rem;

@spacers: {
    0: 0rem;
    1: (@spacer * .25);
    2: (@spacer * .5);
    3: @spacer;
    4: (@spacer * 1.5);
    5: (@spacer * 3);
};


@breakpoints: {
  xs: @screen-xs-min;
  sm: @screen-sm-min;
  md: @screen-md-min;
  lg: @screen-lg-min;
}


@displays: none, inline, inline-block, block, table, table-row, table-cell, flex, inline-flex;

//------------------------------------------------
// Spacing
//------------------------------------------------


each(@spacers, .(@sv, @sk, @si) {
  .p-@{sk} {
    padding: @sv; 
  }

  .mx-@{sk} {
    margin-left: @sv; 
    margin-right: @sv; 
  } 
  .my-@{sk} {
    margin-top: @sv; 
    margin-bottom: @sv; 
  } 
  .ml-@{sk} {
    margin-left: @sv; 
  } 
  .mr-@{sk} {
    margin-right: @sv; 
  }
  .mt-@{sk} {
    margin-top: @sv; 
  } 
  .mb-@{sk} {
    margin-bottom: @sv; 
  }

  .px-@{sk} {
    padding-left: @sv; 
    padding-right: @sv; 
  } 
  .py-@{sk} {
    padding-top: @sv; 
    padding-bottom: @sv; 
  } 
  .pl-@{sk} {
    padding-left: @sv; 
  } 
  .pr-@{sk} {
    padding-right: @sv; 
  }
  .pt-@{sk} {
    padding-top: @sv; 
  } 
  .pb-@{sk} {
    padding-bottom: @sv; 
  }
  
  // Generate responsive classes, e.g., mx-*-2, my-*-2, etc.
  each(@breakpoints, .(@bpv, @bpk, @bpi) {
    .px-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        padding-left: @sv !important;
        padding-right: @sv !important;
      }
    };

    .py-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        padding-top: @sv !important;
        padding-bottom: @sv !important;
      }
    };

    .pl-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        padding-left: @sv !important;
      }
    };

    .pr-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        padding-right: @sv !important;
      }
    };

    .pt-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        padding-top: @sv !important;
      }
    };

    .pb-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        padding-bottom: @sv !important;
      }
    };
    
    .mx-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        margin-left: @sv !important;
        margin-right: @sv !important;
      }
    };

    .my-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        margin-top: @sv !important;
        margin-bottom: @sv !important;
      }
    };

    .ml-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        margin-left: @sv !important;
      }
    };

    .mr-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        margin-right: @sv !important;
      }
    };



    .mb-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        margin-bottom: @sv !important;
      }
    };

  });   

});



each(@breakpoints, .(@bpv, @bpk, @bpi) {
  each(@spacers, .(@sv, @sk, @si) {
    .mt-@{bpk}-@{sk} {
      @media (min-width: @bpv) {
        margin-top: @sv;
      }
    }
  ;
  });
});

//------------------------------------------------
// Text Alignment
//------------------------------------------------

@alignments: left, right, center;

each(@alignments, .(@av, @ak, @ai) {

  // .text-@{av} {
  //   text-align: @av !important;
  // };

  each(@breakpoints, .(@bpv, @bpk, @bpi) {
    .text-@{bpk}-@{av} {
      @media (min-width: @bpv) {
        text-align: @av !important;
      }
    };
  });
});


//-------------------------------------------------
// Display
//-------------------------------------------------

.d-hidden {
  display: hidden !important;
}

.d-flex {
  display: flex !important;
}

each(@displays, .(@dv, @dk, @di) {
  // Generate display classes, e.g., d-flex, d-inline-block, etc.
  .d-@{dv} {
    display: @dv !important;
  };
  // Generate responsive display classes, e.g., d-*-flex, d-*-inline-block, etc.
  each(@breakpoints, .(@bpv, @bpk, @bpi) {
      .d-@{bpk}-@{dv} {
        @media (min-width: @bpv) {
          display: @dv !important;
        }
      };
    });  
});


//-------------------------------------------------
// Styling
//-------------------------------------------------

.program-phase-title {
  background: @brand-gray;
} 

/* -------------------------------------------------------- */
/* Tables
/* -------------------------------------------------------- */

.show-data-highlight {
  background: #dff0d8 !important;
}

.pending-save-highlight {
  background: lighten(#3c3c3c, 50%) !important;
}

.success-highlight {
  background: lighten(@colors[green3], 10%) !important;
}

.warning-highlight {
  background: lighten(@colors[warning], 15%) !important;
}

.error-highlight {
  background: lighten(@colors[error], 25%) !important;
}